import React from "react";
import { StarSprinkle } from "../../../meteor-common/src/theme/theme_components/StarSprinkle";
import { Svg_plainCircle } from "../../../meteor-common/src/theme/theme_components/Svg_PlainCircle";
import { MeteorAnimatedLogo } from "../../../meteor-common/src/theme/theme_components/MeteorAnimatedLogo";
import { AspectRatio, Container, Flex, Grid, Heading, Text } from "@chakra-ui/react";
import { ImWarning } from "react-icons/all";
import { Link } from "react-router-dom";
import { Icon } from "@chakra-ui/icons";
import { Component_MeteorSocialMedia } from "../../../meteor-common/src/components/misc/Component_MeteorSocialMedia";

export interface ICPRoute_Landing {
  [prop: string]: any;
}

export const Route_Landing: React.FC<ICPRoute_Landing> = () => {
  return (
    <Container display={"flex"} flexDirection={"column"} flexGrow={1} maxW={"md"} h={"100vh"}>
      <Flex
        flexGrow={2}
        position={"relative"}
        direction={"column"}
        alignItems={"stretch"}
        justifySelf={"center"}
        padding={"1em 0"}
      >
        <Flex gap={"0.75em"} direction={"column"} flexGrow={2} justifyContent={"center"} alignItems={"center"}>
          <Grid
            gridTemplateColumns={"1fr auto 1fr"}
            position={"relative"}
            width={"100%"}
            alignSelf={"stretch"}
            alignItems={"center"}
            gap={"0.5em"}
          >
            <Flex direction={"column"} position={"relative"} padding={"0.5em"} h={"100%"} w={"100%"}>
              <Flex direction={"column"} position={"relative"} h={"100%"} w={"100%"}>
                <StarSprinkle size={0.2} />
              </Flex>
            </Flex>
            <AspectRatio w={"7em"} maxW="7em" ratio={1} className={"meteor-logo"}>
              <Flex direction={"column"} padding={"0.25em"} width={"100%"} height={"100%"}>
                <Flex direction={"column"} width={"100%"} height={"100%"} position={"relative"}>
                  <Flex position={"absolute"} width={"90%"} height={"90%"} className={"meteor-logo-circle"}>
                    <Svg_plainCircle />
                  </Flex>
                  <MeteorAnimatedLogo />
                </Flex>
              </Flex>
            </AspectRatio>
            <Flex direction={"column"} position={"relative"} height={"100%"} width={"100%"}>
              <StarSprinkle size={0.3} />
            </Flex>
          </Grid>
          <Heading fontWeight={800} textAlign={"center"}>
            Meteor
          </Heading>
          <Text opacity={0.6} fontWeight={500} align={"center"}>
            Securely store and stake your NEAR tokens and compatible assets with Meteor.
          </Text>
        </Flex>
        <Flex grow={1} direction={"column"} gap={"3em"} align={"center"}>
          <Flex direction={"column"} padding={"1em"} borderRadius={"1em"} bg={"blackAlpha.50"} gap={"0.5em"}>
            <Flex align={"center"} gap={"0.5em"} justify={"center"}>
              <Icon as={ImWarning} color={"orange.400"} />
              <Text align={"center"} color={"orange.4000"} fontWeight={600}>
                Meteor Wallet is currently in <b>alpha</b>
              </Text>
            </Flex>
            <Text fontSize={"0.8em"} opacity={0.65} align={"center"}>
              <i>
                Some things may not work as expected. Please check back for the wider beta release if you'd like more
                stability.
              </i>
            </Text>
          </Flex>
          <Component_MeteorSocialMedia />
          {/*<Button
            as={"a"}
            href={"https://wallet.meteorwallet.app"}
            leftIcon={<IoWalletOutline />}
            variant={"brandAction"}
            size={"lg"}
          >
            Open Wallet
          </Button>*/}
          <Flex grow={1} />
        </Flex>
      </Flex>
      <Flex grow={1} direction={"column"} align={"center"} justifyContent={"flex-end"} padding={"1em"}>
        <Link to={"/terms"}>
          <Text fontSize={"0.8em"} opacity={0.75}>
            Terms and Conditions
          </Text>
        </Link>
      </Flex>
    </Container>
  );
};
