import React from "react";
import { Link, Navigate, NavLink, Route, Routes } from "react-router-dom";
import { Section_PrivacyPolicy } from "./sections/Section_PrivacyPolicy";
import { Section_TermsAndConditions } from "./sections/Section_TermsAndConditions";
import { Flex, Grid, Text } from "@chakra-ui/react";
import "./privacy-policy.scss";
import { Svg_MeteorLogoSimple } from "../../../../meteor-common/src/theme/theme_components/Svg_MeteorLogoSimple";

export interface ICPRoute_PrivacyAndTerms {
  [prop: string]: any;
}

export const Route_PrivacyAndTerms: React.FC<ICPRoute_PrivacyAndTerms> = () => {
  return (
    <Flex direction={"column"} pt={"5em"}>
      <Grid
        top={0}
        left={0}
        right={0}
        position={"fixed"}
        w={"100%"}
        zIndex={1}
        gridTemplateColumns={"1fr 1fr 1fr"}
        padding={"1em"}
        gap={"2em"}
        bg={"rgb(200, 230, 255)"}
        // boxShadow={"0 0 10px 0px rgba(0, 0, 0, 0.1)"}
      >
        <Flex grow={1}>
          <Link to={"/"}>
            <Flex align={"center"} gap={"0.5em"} grow={1}>
              <Flex width={"2em"} height={"2em"}>
                <Svg_MeteorLogoSimple />
              </Flex>
              <Flex>
                <Text fontWeight={700} fontSize={"1.5em"} lineHeight={"1.3em"}>
                  Meteor Wallet
                </Text>
              </Flex>
            </Flex>
          </Link>
        </Flex>
        <Flex grow={1} gap={"1em"}>
          <NavLink to={"privacy"}>
            {({ isActive }) => {
              return <Text decoration={isActive ? "underline" : undefined}>Privacy Policy</Text>;
            }}
          </NavLink>
          <NavLink to={"terms-and-conditions"}>
            {({ isActive }) => {
              return <Text decoration={isActive ? "underline" : undefined}>Terms and Conditions</Text>;
            }}
          </NavLink>
        </Flex>
      </Grid>
      <Routes>
        <Route path={"/privacy"} element={<Section_PrivacyPolicy />} />
        <Route path={"/terms-and-conditions"} element={<Section_TermsAndConditions />} />
        <Route path="*" element={<Navigate to="privacy" replace />} />
      </Routes>
    </Flex>
  );
};
