import { extendTheme, type ThemeConfig } from "@chakra-ui/react";
import "./font/gilroy_new/stylesheet.css";
import { EThemeMode } from "./ThemeStatic";

let chakraTheme: any;

const brandPrimaryColors: any = {
  100: "#8375f8",
  200: "#7768f5",
  300: "#6859ef",
  400: "#653dec",
  500: "#471be8",
  600: "#471be8",
  700: "#471be8",
  800: "#471be8",
  900: "#471be8",
};

const brandSecondaryColors: any = {
  100: "#f18866",
  200: "#ee8562",
  300: "#e87c55",
  400: "#ea7d4e",
  500: "#ea7d4e",
  600: "#ea7d4e",
  700: "#ea7d4e",
  800: "#ea7d4e",
  900: "#ea7d4e",
};

export function getChakraTheme(defaultMode: EThemeMode) {
  if (chakraTheme != null) {
    return chakraTheme;
  }

  const config: ThemeConfig = {
    initialColorMode: defaultMode,
    useSystemColorMode: false,
  };

  chakraTheme = extendTheme(
    {
      colors: {
        brandPrimary: brandPrimaryColors,
        brandSecondary: brandSecondaryColors,
      },
      config,
      textStyles: {
        paragraph: {
          fontWeight: 500,
        },
      },
      fonts: {
        heading: "Gilroy, sans-serif",
        body: "Gilroy, Inter, sans-serif",
      },
      components: {
        Link: {
          variants: {
            // you can name it whatever you want
            primary: ({ colorScheme = "blue", colorMode = "dark" }) => ({
              color: colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`,
              _hover: {
                color: `${colorScheme}.400`,
              },
            }),
          },
          defaultProps: {
            // you can name it whatever you want
            variant: "primary",
          },
        },
        Text: {
          baseStyle: {
            fontWeight: 500,
          },
        },
        CloseButton: {
          variants: {
            keyNavigation: ({ colorMode = "dark" }) => ({
              borderRadius: "full",
              bg: colorMode === "dark" ? "white" : "black",
              color: colorMode === "dark" ? "black" : "white",
            }),
          },
        },
        IconButton: {},
        /*ButtonIcon: {

        },*/
        Button: {
          variants: {
            brandActionInvert: ({ colorMode = "dark" }) => ({
              background: colorMode === "dark" ? "gray.700":"gray.100",
              color: colorMode === "dark" ?  "gray.100" : "gray.900",
              _hover: {
                bg: colorMode === "dark" ? "brandPrimary.300":"brandPrimary.300",
								color:"gray.50"
              },
              // borderColor: colorMode === "light" ? "black" : "white",
            }),
            brandAction: ({ colorMode = "dark" }) => ({
              background: colorMode === "dark" ? "brandPrimary.300" : "brandPrimary.500",
              color: "white",
              _hover: {
                bg: colorMode === "dark" ? "brandPrimary.200" : "brandPrimary.400",
              },
              // borderColor: colorMode === "light" ? "black" : "white",
            }),
            brandLightAction: ({ colorMode = "dark" }) => ({
              // bg: colorMode === "light" ? "blackAlpha.100" : "whiteAlpha.100",
              bg: "rgba(50, 100, 255, 0.1)",
              color: colorMode === "dark" ? "brandPrimary.100" : "brandPrimary.500",
              _hover: {
                bg: "rgba(50, 100, 255, 0.05)",
              },
              // border: "1px solid rgba(50, 100, 255, 0.25)",
              // borderColor: colorMode === "light" ? "black" : "white",
            }),
            topBarIcon: ({ colorMode = "dark" }) => ({
              // borderRadius: "full",
              bg: "transparent",
              color: colorMode === "light" ? "black" : "white",
              // borderColor: colorMode === "light" ? "black" : "white",
            }),
            keyNavigation: ({ colorMode = "dark" }) => ({
              borderRadius: "full",
              bg: colorMode === "light" ? "blackAlpha.100" : "whiteAlpha.100",
              color: colorMode === "light" ? "black" : "white",
            }),
          },
          // 1. We can update the base styles
          baseStyle: {
            borderRadius: "full",
            fontWeight: 600,
          },
        },
        Input: {
          defaultProps: {
            variant: "filled",
          },
          sizes: {
            lg: {
              field: {
                borderRadius: "full",
                fontWeight: 500,
              },
              addon: {
                bg: "white",
              },
            },
            md: {
              field: {
                borderRadius: "full",
                fontWeight: 500,
              },
              addon: {
                bg: "white",
              },
            },
          },
        },
      },
      styles: {
        global: {
          body: {
            // "-webkit-font-smoothing": "antialiased",
            // "-moz-osx-font-smoothing": "auto",
            // fontSmooth: "always",
            fontWeight: 500,
            fontSize: {
              base: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
              // xl: "125%",
            },
          },
        },
      },
    },
    /*withDefaultColorScheme({
      colorScheme: "brandPrimary",
      components: ["Button", "Badge"],
    }),*/
  );
  return chakraTheme;
}
