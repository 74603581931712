import React from "react";
import { Route, Routes } from "react-router-dom";
import { Route_PrivacyAndTerms } from "./routes/privacy_and_terms/Route_PrivacyAndTerms";
import { getChakraTheme } from "../../meteor-common/src/theme/ChakraTheme";
import { EThemeMode } from "../../meteor-common/src/theme/ThemeStatic";
import { ChakraProvider } from "@chakra-ui/react";
import { Route_Landing } from "./routes/Route_Landing";

export interface ICPApp {
  [prop: string]: any;
}

export const App: React.FC<ICPApp> = () => {
  return (
    <ChakraProvider theme={getChakraTheme(EThemeMode.light)}>
      <Routes>
        <Route index={true} element={<Route_Landing />} />
        <Route path={"/terms/*"} element={<Route_PrivacyAndTerms />} />
      </Routes>
    </ChakraProvider>
  );
};
