import { Container, Flex } from "@chakra-ui/react";
import React from "react";
import { TERMS_HTML } from "./terms_const";

export interface ICPSection_TermsAndConditions {
  [prop: string]: any;
}

export const Section_TermsAndConditions: React.FC<ICPSection_TermsAndConditions> = () => {
  return (
    <Flex direction={"column"} className={"privacy-policy"} p={"3em"}>
      <Container>
        <div dangerouslySetInnerHTML={{ __html: TERMS_HTML }} />
      </Container>
    </Flex>
  );
};
